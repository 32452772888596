import * as React from 'react';
import { useMutation } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { BooleanField, Button, DateField, ChipField, FunctionField, ImageField, RichTextField, ReferenceField, Show, SimpleShowLayout, TextField, useDataProvider, useRecordContext } from 'react-admin';

const PromotionalofferTitle = () => {
  const record = useRecordContext();
  return (
    <span>Promotional offer {record ? `"${record.title}"` : ''}</span>
  );
};

const DaysOfWeekRender = (record) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  if (record.daysOfWeek) {
    return (
      <ul>
        {
          JSON.parse(record.daysOfWeek)
            .map((day) => <li key={day}>{days[day]}</li>)
        }
      </ul>
    );
  }
  else {
    return <span>-</span>;
  }
};

const sendPromoNotificationIntegrationStyles = makeStyles({
  label: {
    fontSize: '0.75em',
    marginTop: 0,
    marginBottom: '0.2em',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  value: {
    marginTop: '0.2em',
    marginBottom: 0,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    color: 'rgba(0, 0, 0, 0.87)',
    fontStyle: 'italic',
  },
});

const SendPromoNotification = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const classes = sendPromoNotificationIntegrationStyles();
  const { data, mutate, isLoading } = useMutation(
    ['sendPromoNotification'],
    () => dataProvider.sendPromoNotification(record.id),
  );
  const sendPromoNotificationLabel = isLoading ? 'Sending...' : `Send Push Notification To All Users In Segment ID ${record.segment}`;

  return record && record.segment ? (
    <>
      <Button disabled={isLoading} onClick={() => mutate()} color="secondary" variant="outlined" size="medium">
        {sendPromoNotificationLabel}
      </Button>
      <p className={classes.value}>{data?.json === "success" ? 'Sent' : ''}</p>
    </>
  ) : null;
};

export const PromotionalofferShow = props => (
  <Show {...props} title={<PromotionalofferTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="title" />
      <TextField source="headline" />
      <DateField source="published" options={{ timeZone: 'UTC'}} />
      <DateField source="expiry" options={{ timeZone: 'UTC'}} />
      <BooleanField looseValue source="allDay" />
      <ImageField source="imageUrl" />
      <TextField source="ordering" />
      <FunctionField label="Days of Week" render={DaysOfWeekRender} />
      <RichTextField source="description" />
      <BooleanField looseValue source="isFeatured" />
      <ReferenceField label="Site" source="site_id" reference="sites">
        <ChipField source="siteName" />
      </ReferenceField>
      <TextField source="segment" />
      <SendPromoNotification />
    </SimpleShowLayout>
  </Show>
);

export default PromotionalofferShow;
