import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'ra-core';
import { combineDataProviders } from 'react-admin';
import axios from 'axios';

window.Buffer = window.Buffer || require('buffer').Buffer;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('X-Api-Key', process.env.REACT_APP_API_KEY);
  options.headers.set('Authorization', window.localStorage.getItem('_gasAccessToken'));
  return fetchUtils.fetchJson(url, options);
};

const API_URL = process.env.REACT_APP_API_URL;
const dataProvider1 = simpleRestProvider(API_URL, httpClient);

const API_URL2 = process.env.REACT_APP_API_URL2;
const dataProvider2 = simpleRestProvider(API_URL2, httpClient);

const API_URL3 = process.env.REACT_APP_API_URL3;
const dataProvider3 = simpleRestProvider(API_URL3, httpClient);

const dataProvider = combineDataProviders(resource => {
  switch (resource) {
    case 'maintenancemodes':
    case 'referrals':
    case 'frequentlyaskedquestions':
      return dataProvider3;
    case 'giftcards':
    case 'giftcardtransactions':
    case 'campaigncodes':
    case 'campaigncoderedemptions':
    case 'referralamounts':
    case 'tranxactors':
      return dataProvider2;
    default:
      return dataProvider1;
    // throw new Error(`Unknown resource: ${resource}`);
  }
});

export const myDataProvider = {
  ...dataProvider,
  sitesUpdater: async () => {
    return await httpClient(`${process.env.REACT_APP_API_URL3}/gas/sites/siteintegration`);
  },
  sendTransactionEmail: async id => {
    return await httpClient(`${process.env.REACT_APP_API_URL3}/sendtransactionemailforadmin?id=${id}`);
  },
  getGiftCardBalance: async id => {
    return await httpClient(`${process.env.REACT_APP_API_URL2}/giftcards/balance/${id}`);
  },
  sendPromoNotification: async id => {
    return await httpClient(`${process.env.REACT_APP_API_URL3}/sendpromonotificationforadmin?promoId=${id}`);
  },
  create: async (resource, params) => {
    if (resource === 'promotionalofferscalendar') {
      resource = 'promotionaloffers';
      return dataProvider.update(resource, params);
    }

    if (resource !== 'promotionaloffers') {
      // fallback to the default implementation
      return dataProvider.create(resource, params);
    }

    if (params.data.imageUrl) {
      const newPictures = [params.data.imageUrl].filter(p => p.rawFile instanceof File);
      if (newPictures.length > 0) {
        // get S3 presigned URL
        const res = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL3}/s3/adminUploadFile?bucket=gas-app-assets&key=promotions/${newPictures[0].rawFile.name}&operation=putObject`,
          headers: {
            'X-Api-Key': process.env.REACT_APP_API_KEY,
            'Authorization': window.localStorage.getItem('_gasAccessToken')
          }
        });

        // upload image to S3
        const sendFile = await axios.put(res.data, newPictures[0].rawFile, {
          headers: { 'x-amz-acl': 'public-read', 'Content-Type': newPictures[0].rawFile.type },
        });

        if (sendFile.status === 200) {
          return dataProvider.create(resource, {
            data: {
              ...params.data,
              imageUrl: `https://gas-app-assets.s3-ap-southeast-2.amazonaws.com/promotions/${newPictures[0].rawFile.name}`,
            },
          });
        }
        else {
          return Promise.reject('Failed to upload image');
        }
      } else {
        return dataProvider.create(resource, params);
      }
    }
    else {
      return dataProvider.create(resource, params);      
    }
  },
  getOne: (resource, params) => {
    if (resource === 'promotionalofferscalendar') {
      resource = 'promotionaloffers';
    }
    if (resource === 'sites') {
      params = { ...params, meta: { isVip: true } };
    }
    return dataProvider.getOne(resource, params);
  },
  getList: (resource, params) => {
    if (resource === 'promotionalofferscalendar') {
      resource = 'promotionaloffers';
    }
    if (resource === 'sites') {
      params = { ...params, meta: { isVip: "true" } };
    }
    return dataProvider.getList(resource, params);
  },
  update: async (resource, params) => {
    if (resource === 'promotionalofferscalendar') {
      resource = 'promotionaloffers';
      return dataProvider.update(resource, params);
    }

    if (resource !== 'promotionaloffers') {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }

    /**
     * For promotional offers update only, convert uploaded image in base 64 and attach it to
     * the `picture` sent property, with `src` and `title` attributes.
     */

    // Freshly dropped pictures are File objects and must be converted to base64 strings
    const newPictures = [params.data.imageUrl].filter(p => p.rawFile instanceof File);

    if (newPictures.length > 0) {
      // get S3 presigned URL
      const res = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL3}/s3/adminUploadFile?bucket=gas-app-assets&key=promotions/${newPictures[0].rawFile.name}&operation=putObject`,
        headers: {
          'X-Api-Key': process.env.REACT_APP_API_KEY,
          'Authorization': window.localStorage.getItem('_gasAccessToken')
        }
      });

      // upload image to S3
      const sendFile = await axios.put(res.data, newPictures[0].rawFile, {
        headers: { 'x-amz-acl': 'public-read', 'Content-Type': newPictures[0].rawFile.type },
      });

      if (sendFile.status === 200) {
        return dataProvider.update(resource, {
          data: {
            ...params.data,
            imageUrl: `https://gas-app-assets.s3-ap-southeast-2.amazonaws.com/promotions/${newPictures[0].rawFile.name}`,
          },
        });
      }
      else {
        return Promise.reject('Failed to upload image');
      }
    } else {
      return dataProvider.update(resource, params);
    }
  },
};
