import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Children, cloneElement } from 'react';
import { AutocompleteInput, BooleanField, ChipField, Datagrid, DateField, DateInput, EditButton, ImageField, List, ReferenceField, ReferenceInput, ShowButton, TextField, TextInput } from 'react-admin';

const usePromotionalofferListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1,
  },
});

const PromotionalofferListActionToolbar = ({ children, ...props }) => {
  const classes = usePromotionalofferListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {
        Children.map(children, button => cloneElement(button, props))
      }
    </div>
  );
};

const promotionalofferFilters = [
  <TextInput source="title" label="Search Title" />,
  <TextInput source="headline" label="Search Headline" />,
  <DateInput source="published" label="Search Published" />,
  <DateInput source="expiry" label="Search Expiry" />,
  <ReferenceInput source="site_id" reference="sites" label="Search Sites">
    <AutocompleteInput optionText="siteName" label="Search Sites" />
  </ReferenceInput>,
];

export const PromotionalofferList = () => (
  <List filters={promotionalofferFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="title" />
      <TextField source="headline" />
      <DateField source="published" options={{ timeZone: 'UTC'}} />
      <DateField source="expiry" options={{ timeZone: 'UTC'}} />
      <ImageField source="imageUrl" />
      <TextField source="ordering" />
      <BooleanField looseValue source="isFeatured" />
      <ReferenceField label="Site" source="site_id" reference="sites">
        <ChipField source="siteName" />
      </ReferenceField>
      <TextField source="segment" />
      <PromotionalofferListActionToolbar>
        <EditButton />
        <ShowButton />
      </PromotionalofferListActionToolbar>
    </Datagrid>
  </List>
);

export default PromotionalofferList;
